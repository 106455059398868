import React from 'react';
import { Link } from "react-router-dom";

export const WooCommercePluginInstallation = ({data, match}) => {
	return <div className="wooCommerceSection">
		<header>
			<img src="/images/wordpress_logo.png" alt="wordpress logo" />
		</header>
		<div>
			<a href="https://wordpress.org/plugins/">Install Plug-In</a>
		</div>
		<footer>
			<Link to={ `${ match.url }/home/recommendations/install-pixel/plugin-installation-checking` }>Continue</Link>
		</footer>
	</div>
}
