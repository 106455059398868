import { useState, useEffect, FC, ReactElement, Dispatch, SetStateAction } from 'react';
import {
	Switch,
	Route,
	Link,
	RouteComponentProps
} from "react-router-dom";

import { HttpService } from "../../../services/HttpService"

import { Recommendations, RecommendationsData } from "./Recommendations"
import { Dashboard, DashboardData } from "./Dashboard"
import { matchActiveUrl } from "../../../services/RouterService"

interface HomeState {
	recommendations: RecommendationsData,
	dashboard: DashboardData
}

type HomeAPI = HomeState

export const Home = ({ match, clientData }): RouteComponentProps<{}> => {
	const fetchURL = "https://self-service.feelter.com/api/json/homeDB.json"
	const [recommendationsData, setRecommendationsData] = useState<HomeState['recommendations']>({} as RecommendationsData)
	const [dashboardData, setDashboardData] = useState<HomeState['dashboard']>({} as DashboardData)

	useEffect(() => {
		const fetchData = async () => {
			const result = await HttpService.getAxiosClient().get(fetchURL);
			setRecommendationsData(result.data.home.recommendations)
			setDashboardData(result.data.home.dashboards)
		}
		fetchData()
	}, []);

	return <main>
		<header className="home">
			{ recommendationsData.title && <Link className={ "navTabLink " + (matchActiveUrl(`${ match.url }/recommendations`) ? 'active' : '') } to={ `${ match.url}/recommendations` }>{ recommendationsData.title.toUpperCase() }</Link> }
			{ dashboardData.title && <Link className={ "navTabLink "  + (matchActiveUrl(`${ match.url }/dashboard`) ? 'active' : '') } to={ `${ match.url}/dashboard` }>{ dashboardData.title.toUpperCase() }</Link> }
		</header>
		<Switch>
			{ recommendationsData.welcome && 
				<Route 
					path={ `${ match.url }/recommendations` }
					render={ () => <Recommendations data={ recommendationsData } clientData={ clientData } /> } 
				/>
			}
			{ recommendationsData.welcome && 
				<Route 
					path={ `${ match.url}/dashboard` }
					render={ () => <Dashboard data={ dashboardData } /> }  
				/>
			}
		</Switch>
	</main>
}