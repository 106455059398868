import { useState } from 'react';
import { Link } from "react-router-dom";

import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

import { matchActiveUrl } from "../../services/RouterService"

export const AccSettings = ({ match, data }) => {
	console.log("data ", data)
	const [uploadImage, setUploadImage] = useState(null)
	
	const handleChange = event => {
		setUploadImage(URL.createObjectURL(event.target.files[0])) 
	}

	const account_settings_data = data.main.account_settings_labels
	const options = account_settings_data.industries
	console.log("options ", options)
	const defaultOption = options[0];
	console.log("defaultOption ", defaultOption)


	return <div className="accSettings">
		<h2>{ account_settings_data.todo_label }</h2>
		<section>
			<nav className="settingsTabContainer">
				<Link className={ "navTabLink " + (matchActiveUrl(`${ match.url }/profile`) ? 'active' : '') } to={ `${match.url}/profile` }>Profile settings</Link>
			</nav>
			<form className="accSettingsForm">
				<div>
					<h3>{ account_settings_data.profile_pic }</h3>
					<div className="changePicContainer">
						<img src={ uploadImage ? uploadImage : '/clients_data/defaultProfPic.png' } alt="" />
						<div className="changePicLabelAction">
							<h5>{ account_settings_data.todo_label }</h5>
							<p>{ account_settings_data.todo_description }</p>
							<label htmlFor="imageUpload" className="custSubmit">
								<input type="file" onChange={ handleChange } id="imageUpload" accept="image/*" hidden />
								{ account_settings_data.upload_picture }</label>
						</div>
					</div>
				</div>
				<div>
					<h3>{ account_settings_data.form }</h3>
					<div className="updPersInfoInputs">
						<input type="text" id="persInfName" placeholder="" />
						<label htmlFor="persInfName">Full Name</label>
					</div>
					<div className="updPersInfoInputs">
						<input type="email" id="persInfEmail" placeholder="" />
						<label htmlFor="persInfName">Email</label>
					</div>
					<div className="updPersInfoInputs">
						<input type="text" id="persInfOrg" placeholder="Organization" disabled />
						<label htmlFor="persInfName">Organization</label>
					</div>
					<div className="updPersInfoInputs">
						<Dropdown options={ options } value={ defaultOption } placeholder="Select an option" />
					</div>
				</div>
				<input type="submit" className="custSubmit" value={ account_settings_data.request_btn } />
			</form>
		</section>
	</div>
}