import React from "react";

import { DahboardStatus } from "./DahboardStatus"
import  DemoArea  from "./Dashboard_pixel"

export interface DashboardData {
	title: string
}

export const Dashboard = (props) => <div className="dashboard">
			<DemoArea></DemoArea>			
	</div>