import { applyMiddleware, compose, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'

import { monitorReducerEnhancer } from './enhancers/monitorReducer'
import loggerMiddleware from './middleware/logger'
import { rootReducer } from './reducers/rootReducer'

export const configureStore = preloadedState => {
  const middlewares = [loggerMiddleware, thunkMiddleware]
  console.log({middlewares})
  const middlewareEnhancer = applyMiddleware(...middlewares)

  const composedEnhancers = compose(middlewareEnhancer, monitorReducerEnhancer)
  const store = createStore(rootReducer, preloadedState, composedEnhancers)
  console.log({preloadedState})

  return store
}