import React from 'react';
import { useRef} from 'react';

import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	useHistory,
	useLocation,
	useParams
} from "react-router-dom";

export const PixelInstall = ({ data, match }) => {

	let history = useHistory();

	const [showModal, setIsOpen] = React.useState(true);

	const handleCloseModal = () => {
		setIsOpen(false);
		history.goBack()
	}

	const refContainer = useRef();
	
	const [copySuccess, setCopySuccess] = React.useState(true);

	const copyCodeToClipboard = () => {
		const el = refContainer as any
		el.current.select()
		document.execCommand("copy")
		setCopySuccess(true)
	}

	const headDescription = `Copy and paste the pixel code into the bottom of the header section of your site, just above the </head> tag. `
	
	return <div className="mainModalInside pixelInstall">
		<div className='mainContent'>
			<h5>{ headDescription }</h5>
			<div className="pixelScript">
				<textarea disabled ref={ refContainer } cols={ 118 } rows={ 12 }>{`<script type="text/javascript">
	<!--//--><![CDATA[//><!--
	var fltPx = new Image();
	fltPx.src = "https://api.feelter.com/api/px.png?token=Twaa3MYYUmnPxwiRoaX5yBDqpVmZRUOSBRdkEoHQkeH4vJXWgrqXv6qskEaN";
	//--><!]]>
</script>
	`}</textarea>
				<button className="copyContent" onClick={ () => copyCodeToClipboard() }>Copy code</button>
			</div>
		</div>
		<footer>
			<Link onClick={ handleCloseModal } className="backButton">BACK</Link>
			<Link to={ `${ match.url }/home/recommendations/install-pixel/plugin-installation-checking` } className="nextButton">CONTINUE</Link>
		</footer>
	</div>
}