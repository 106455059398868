import { useState, useEffect } from 'react';

import './home.css';
import {
	Routes,
	Switch,
	Route,
	RouteComponentProps,
	useLocation
} from "react-router-dom";

import { HttpService } from "../../services/HttpService"

import { HeaderFixedNav } from "./HeaderFixedNav"
import { SideFixedNav } from "./SideFixedNav"
import { Home } from "./home/Home"
import { AddSocial } from "./home/AddSocial"
import { AccSettings } from './AccSettings'
import { ProductCatalog } from "./productCatalog/productCatalog"
import { ModalDialogFixedContent } from "./modalDialogsWelcomeTo/modalDialogFixedContent"


import { integrityChecking } from "../../services/RouterService"

export interface MainData {
	main: [],
	feelter_installation: {
		is_installed: string,
		installed_label: string,
		not_installed_label: string
	},
	website: {
		logo: string
		url: string
	},
	account: {
		full_name: string,
		email: string,
		logo: string
	},
	sections: {
		home: {
			is_visible: boolean,
			label: string
		},
		design: {
			is_visible: boolean,
			label: string
		},
		prod_catalog: {
			is_visible: boolean,
			label: string
		},
		insights: {
			is_visible: boolean,
			label: string
		}	
	},
	account_option_labels: {
		account_settings: string,
		store_settings: string,
		logout: string
	},
	account_settings_labels: {
		tabs: {
			profile: string,
			plan: string,
			social: string
		},
		profile_pic: string,
		todo_label: string,
		todo_description: string,
		upload_picture: string,
		form: string,
		input_name: string,
		input_email: string,
		input_org: string,
		input_options_name: string
	},
	industries: [
		{ value: string, label: string },
		{ value: string, label: string },
		{ value: string, label: string },
		{ value: string, label: string },
		{ value: string, label: string },
		{ value: string, label: string },
		{ value: string, label: string }
	],
	data: string
}

type MainAPI = MainData


export const Main = ({ match, clientData}: RouteComponentProps<{}>) => {
	console.log({clientData})
	const fetchURL = "https://self-service.feelter.com/api/json/mainLabels.json"
	const [mainData, setMainData] = useState({})

	useEffect(() => {
		const fetchData = async () => {
			const result = await HttpService.getAxiosClient().get(fetchURL);
			setMainData(result.data);
		}
		fetchData()
	}, []);
	
	return <div>
		<HeaderFixedNav match={ match } props={ mainData } clientData={ clientData.data } />
		{ mainData && <main>
			<SideFixedNav props={ mainData } match={ match } />
			<Switch>
				<Route 
					path={ `${ match.url }/home` }
					render={ props => <Home match={ props.match } clientData={ clientData.data } /> }
				/>
				<Route path={ `${ match.url }/add-social` } component={ AddSocial } />
				<Route 
					path={ `${ match.url }/account-settings` }
					render={ () => <AccSettings data={ mainData } match={ match } /> }
				/>
				<Route 
					path={ `${ match.url }/product-catalog` }
					render={ () => <ProductCatalog data={ mainData } match={ match } /> }
				/>
			</Switch>
		</main> }


			<Switch>
				<Route 
					path={ `**/install-pixel` }
					render={ () =>  <ModalDialogFixedContent data={ mainData } match={ match } /> }
				/>
			</Switch>
	</div>
}