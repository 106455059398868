
export function testReducer(state = {}, action) {
  switch (action.type) {
    // case CREATE_POST: {
    //   // Omit actual code
    //   break
    // }
    default:
      return state
  }
}