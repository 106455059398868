import { useState } from 'react';
import { Link, useLocation } from "react-router-dom";

import { Bell } from "../../svgIcons/Bell" 
import { Help } from "../../svgIcons/Help" 
import { PersonalAcc } from '../../svgIcons/PersonalAcc';
import { ListAccSettings } from './ListAccSettings';

import './home.css';

export const HeaderFixedNav = ({ match, props, clientData }) => {
	const [showListAccSettings, setListAccSettings] = useState(false) 
	
	const getHostname = url => {
		return new URL(url).hostname;
	}

	let location = useLocation()

	return props.main 
		? <header className="homeHeader flexSpaceBetween">
			<a href="https://www.feelter.com/" target="_blank">
				<img src="/images/feelter.png" alt="Feelter" />
			</a>
			<div className="headerClientProps">
				<Link className="installStatus" to={{
					pathname: `${ match.url }/home/recommendations/install-pixel/feelter-installation`,
					state: location }} 
				>
					<svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M10.5 0.5C4.71053 0.5 0 5.21053 0 11C0 16.7895 4.71053 21.5 10.5 21.5C16.2895 21.5 21 16.7895 21 11C21 5.21053 16.2895 0.5 10.5 0.5ZM16.3684 8.23684L9.6579 14.8947C9.26316 15.2895 8.63158 15.3158 8.21053 14.9211L4.6579 11.6842C4.23684 11.2895 4.21053 10.6316 4.57895 10.2105C4.97368 9.78947 5.63158 9.76316 6.05263 10.1579L8.86842 12.7368L14.8684 6.73684C15.2895 6.31579 15.9474 6.31579 16.3684 6.73684C16.7895 7.1579 16.7895 7.81579 16.3684 8.23684Z" fill="#38A640"/>
					</svg>
					{ props.main.feelter_installation.installed_label }
				</Link>
				<a href={ clientData.domain } className="iconContainer localOrg">
					<PersonalAcc fill="#AAAAAA" />
					{ getHostname(clientData.domain) }
				</a>
				<div>
					<span>
						<Help fill="#AAAAAA" />
					</span>
					<span className="notification">
						<Bell fill="#AAAAAA" />
					</span>
					<button className="toggleAppSettings" onClick={ () => setListAccSettings(!showListAccSettings) } >
						<img src="/clients_data/defaultProfPic.png" alt="" />
					</button>
					{ showListAccSettings && <ListAccSettings match={ match } props={ props.main.account_option_labels } /> }
				</div>
			</div>
		</header>
		: <div>Loading</div>
}