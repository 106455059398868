import { useState, useEffect, useRef } from 'react';

import { useForm, UseFormRegister } from 'react-hook-form';

import { HttpService } from "../services/HttpService"
import UserService from "../services/UserService"

import { enrichUserData } from '../actions/userData.action'

import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
	Link
} from "react-router-dom";

import { connect } from 'react-redux'

// import { back_db } from "../../public/db/back_db.json"

export interface IFormValues {
	email: string,
	password: string
}

export interface InputProps {
	htmlFor: string,
	label: string,
	id: string,
	type: string,
	placeholder: string,
	register: UseFormRegister<IFormValues>
}

export interface ProdCategorData {
	prod_categories_labels: {
		title: string,
		name: {
			label: string,
			placeholder: string
		},
		domain: {
			label: string,
			placeholder: string
		},
		product_categories: string,
		categories: string[],
		submit_btn: string
	}
}

interface createJson {
	name: string,
	domain: string,
	categories: string[]
}

const mapStateToProps = (state, ownProps) => ({
	test: state.test,
	userData: state.userData
})
const mapDispatchToProps = {
	enrichUserData
}

const EnrichingUserData = props => {
	
	const enriching_new_users_data_url = "https://self-service.feelter.com/api/json/enriching_new_users_data_labels.json"
	const baseURL = `https://self-service.feelter.com/api/settings/user/` + UserService.getUserId();
	
	const [categoriesSelectionData, setCategoriesSelectionData] = useState({} as ProdCategorData)
	const [isRegistrationCompleted, setRegistrationComplete] = useState(false)
	
	useEffect(() => {
		const fetchData = async () => {
			const result = await HttpService.getAxiosClient().get(enriching_new_users_data_url);
			setCategoriesSelectionData(result.data);
		}
		fetchData()
	}, []);

	const fullNameRef = useRef(null)
	const domainRef = useRef(null)
	const checkboxContainerRef = useRef(null)

	const [post, setPost] = useState(null);
	useEffect(() => {
		const fetchData = async () => {
			const result = await HttpService.getAxiosClient().get(baseURL);
			setPost(result.data);
		}
		fetchData()
	}, []);

	const enriching_new_users_data_labels_post = async e => {
		e.preventDefault()
		const selectedCategories = checkboxContainerRef.current.querySelectorAll("input[type=checkbox]:checked")
		const categoriesIDs = []
		selectedCategories.forEach(e => categoriesIDs.push(e.name) )
		const data = {
			fullName: fullNameRef.current.value,
			domain: domainRef.current.value,
			choosenCategories: categoriesIDs,
			clientID: post.data.apiKey
		}

		const result = await HttpService.getAxiosClient().post(baseURL, data)
		if (result.status === 200) {
			props.enrichUserData(data)
			setRegistrationComplete(true)
		}

		return false
	}

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<IFormValues>();

	  return isRegistrationCompleted 
			? <Redirect to="/main/home/recommendations" />
			: <section className="prodCategory">
		{ categoriesSelectionData.prod_categories_labels && <div className="formContainer">
			<header className="flexCenter">
				<a href="https://www.feelter.com/" target="_blank"><img src="/images/feelter.png" alt="www.feelter.com" /></a>
				<h2>{ categoriesSelectionData.prod_categories_labels.title }</h2>
			</header>
			<form onSubmit={ enriching_new_users_data_labels_post } >
				<div className="updPersInfoInputs">
					<input type="text" id="fullName" name="fullName" placeholder={ categoriesSelectionData.prod_categories_labels.name.placeholder } ref={ fullNameRef } />
					<label htmlFor="fullName">{ `${ categoriesSelectionData.prod_categories_labels.name.label }**` }</label>
				</div>
				<div className="updPersInfoInputs">
					<input  type="url" id="webDomain" name="webDomain" placeholder={ categoriesSelectionData.prod_categories_labels.domain.placeholder } ref={ domainRef } />
					<label htmlFor="webDomain">{ `${ categoriesSelectionData.prod_categories_labels.domain.label }*` }</label>
				</div>
				<h4>{ categoriesSelectionData.prod_categories_labels.product_categories }</h4>
				<div className="productCategorCheckboxes" ref={ checkboxContainerRef }>
					{ 
						categoriesSelectionData.prod_categories_labels.categories.map(category => {
							const [id, label] = Object.entries(category)[0]
							return <div className="checkboxCont">
								<input type="checkbox" value={ id } id={ id } name={ id } />
								<label htmlFor={ id }>{ label }</label>
							</div>
						})
					}
				</div>
				<input 
					type="submit" 
					className="custSubmit" 
					value={ categoriesSelectionData.prod_categories_labels.submit_btn } 
				/>
			</form>
		</div> }
		<div className="styledSide"></div>
	</section>
}


const comp = connect(mapStateToProps, mapDispatchToProps)(EnrichingUserData)
export { comp as EnrichingUserData }