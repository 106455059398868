export const AddSocial = () => {
	return <section>
		<div className="addSocial">
			<div className="imgCont">
				<img src="/images/addSocial.png" alt="" />
			</div>
			<form action="">
				<h2>Add Social Proof to your Website</h2>
				<p>Create and set your first Social Feed Now</p>
				<input type="submit" className="custSubmit" value="Start!"/>
			</form>
		</div>
			<div className="overlay"></div>
	</section>
}