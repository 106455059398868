import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Column } from '@ant-design/plots';

const DemoArea1 = () => {

  const data = [
    {
      "timePeriod": "2006 Q3",
      "details": 100
    },
    {
      "timePeriod": "2006 Q4",
      "details": 120
    },
    {
      "timePeriod": "2007 Q1",
      "details": 50
    },
    {
      "timePeriod": "2007 Q2",
      "details": 30
    },
    {
      "timePeriod": "2007 Q3",
      "details": 20
    },
    {
      "timePeriod": "2007 Q4",
      "details": 50
    },
    {
      "timePeriod": "2007 Q4",
      "details": 60
    },
    {
      "timePeriod": "2007 Q4",
      "details": 80
    },
    {
      "timePeriod": "2007 Q4",
      "details": 200
    },
    {
      "timePeriod": "2007 Q4",
      "details": 400
    },
    {
      "timePeriod": "2007 Q4",
      "details": 150
    }   
    ];

  const config = {
    data,
    height:84,
    xField: 'timePeriod',
    yField: 'details',
    xAxis: {
      label:null,
      range: [0, 1],  
      grid: null  
    },
    yAxis: {
      label:null,
      grid: null
    },
    
  };

return <div className = "mentions">
            <h4>{ "Mentions" }
                <span className="greySign1">&#9432;</span>
            </h4>
            <b>6,560</b>
            <Column {...config} />
            <div className="someNum">
                <p>60%</p>
            </div> 
        </div>
};

export default DemoArea1;