import React from 'react';
import { useState, useEffect, FC, ReactElement, Dispatch, SetStateAction } from 'react';
import { ProductTable } from "./productTable"
import { HttpService } from "../../../services/HttpService"

import "./productTable.css"

interface ProductState {
	columns?: any,
	data?: string[],
	product_info: any[]
}

export const ProductCatalog = ({ match, data }) => {

	const fetchURL = "https://self-service.feelter.com/api/clients/61e03f3ce8db83eb2dbf7fad/products"

	const [productListData, setProductListData] = useState(null as ProductState)
	
	useEffect(() => {
		const fetchData = async () => {
			const result = await HttpService.getAxiosClient().get(fetchURL);
			setProductListData(result.data);
		}
		fetchData().then( () => {
			console.log('otrabotalo')
		})
	}, []);

	const columns = React.useMemo(
		() => [
			{
				Header: 'Title',
				accessor: 'title',
				maxWidth: 110,
				className: "tableTitle"
			},
			{
				Header: 'Description',
				accessor: 'description',
				maxWidth: 110
			},
			{
				Header: 'Image',
				accessor: 'image',
				maxWidth: 20,
				Cell: props => <img className="productImage" src={ props.value } ></img>
			},
			{
				Header: 'Product URL',
				accessor: 'url',
				maxWidth: 224,
				Cell: props => <a className="productUrl" href={ props.value }>{ props.value }</a>
			}
		],
		[]
	)

	  return <div className="productsTable">
		<h1>Product catalog</h1>
		{
			productListData && <ProductTable columns={ columns } data={ productListData } />
		}
	</div> 
}
