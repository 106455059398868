import { useState, useEffect } from 'react';

import { HttpService } from "./services/HttpService"

import './App.css';

import { Main } from "./components/main/Main"
import { EnrichingUserData } from "./components/EnrichingUserData"

import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect
} from "react-router-dom";

import UserService from "./services/UserService"

import { connect } from 'react-redux'

export interface ClientParts {
	fullName: string, 
	domain: string, 
	data: any,
	choosenCategories: string[]
}

const mapStateToProps = (state, ownProps) => ({
	test: state.test,
	userData: state.userData
})

const App = (props) => {
	const clientDataUrl = `https://self-service.feelter.com/api/settings/user/` + UserService.getUserId();
	const [clientData, setClientData] = useState({} as ClientParts)
	const [isLoading, setLoading] = useState(false)

	useEffect(() => {
		const fetchData = async () => {
			const result = await HttpService.getAxiosClient().get(clientDataUrl);
			setClientData(result.data);
		}
		fetchData()
	}, []);

	return isLoading ? 
		<p>Loading</p>
		: <Router>
			<Switch>
				<Route exact path="/"><Redirect to="/main/home/dashboard" /></Route>
				<Route path="/product-categories" component={ EnrichingUserData } />
				{
					clientData.data
					&& <Route path="/main" component={ ({ match }) => {
						return (clientData.data.fullName && clientData.data.domain && clientData.data.choosenCategories && clientData.data.choosenCategories.length)
							|| (props.userData.fullName && props.userData.domain && props.userData.choosenCategories && props.userData.choosenCategories.length)
						? <Main match={ match } clientData={ clientData } />
						: <Redirect to="/product-categories" />
					}} />
				}
			</Switch>
		</Router>
}

const comp = connect(mapStateToProps, {})(App)
export { comp as App }

// function getCorrectRoute 