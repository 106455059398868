import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Area } from '@ant-design/plots';
import  DemoArea1  from "./Dashboard_mentions"
import  DemoArea2  from "./Dashboard_analytics"
import { NONAME } from 'dns';

const DemoArea = () => {

	const data = [
		{
			"timePeriod": "1",
			"details": 10
		},
		{
			"timePeriod": "2",
			"details": 30
		},
		{
			"timePeriod": "3",
			"details": 40
		},
		{
			"timePeriod": "4",
			"details": 40
		},
		{
			"timePeriod": "5",
			"details": 10
		},
		{
			"timePeriod": "6",
			"details": 40
		},
		{
			"timePeriod": "7",
			"details": 5
		},
		{
			"timePeriod": "8",
			"details": 2
		},
		{
			"timePeriod": "9",
			"details": 10
		},
		{
			"timePeriod": "10",
			"details": 30
		},
		{
			"timePeriod": "11",
			"details": 10
		},
		
	 
	]

	// const config = {
	//   data,
	//   xField: 'timePeriod',
	//   yField: 'details',
	//   xAxis: {
	//     range: [0, 1],
			
	//   },
	// };

	const config = {
		data,
		height:   84,
		xField: 'timePeriod',
		yField: 'details',
		color: '#CF9FFF',
		line: { style: { stroke: '#CF9FFF' } },
		smooth: true,
		xAxis: {
				grid: null,
				line: null, //{ style: { stroke: '#aaa' } }
				label: null
			},
		yAxis: {
				grid: null,
				nice: true,
				line: null,
				label: null,
				range: [0, 0.9],
				
			},
		
	};

	
	return <> 
		<div className='dashboardContainer'> 
			<div className = "dashboardFeelterStatus">		
					<h4>{ "</> Feelter installation" }</h4>
					<p> Status: <span style={{color: "green"}}>&#10004;Pixel connected</span></p>
					<p style={{color: "green"}}>Reconnected</p>
			</div>	
			<div className = "indexed_products">
				<h4 className='indexed_products_h4'>{ "Indexed Products" }<span className="greySign">&#9432;</span></h4>
				<b>8,846</b>                   
				<Area {...config} />
				<div className="someNum">
					<p>1,234</p>
				</div>
			</div>                
			<DemoArea1></DemoArea1>                
		</div>
		<DemoArea2></DemoArea2>
	</>
};

export default DemoArea;