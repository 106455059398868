import { ENRICHING_USER_DATA }  from '../actions/userData.action'

export function userDataReducer(state = {}, action) {
  switch (action.type) {
    case ENRICHING_USER_DATA: {
        return { ...state, ...action.payload }
    }
    default:
      return state
  }
}