import { Link } from "react-router-dom";

import { matchActiveUrl } from "../../services/RouterService"
import UserService from "../../services/UserService";

export const ListAccSettings = ({ match, props }) => <div className="listAccSetContainer">
	<ul>
		<li>
			<Link className={ "navTabLink " + (matchActiveUrl(`${ match.url }/account-settings`) ? 'active' : '') } to={ `${ match.url }/account-settings/profile` } >
				<svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M7 0.5C4.96456 0.5 3.30859 2.15596 3.30859 4.19141C3.30859 6.22685 4.96456 7.88281 7 7.88281C9.03544 7.88281 10.6914 6.22685 10.6914 4.19141C10.6914 2.15596 9.03544 0.5 7 0.5Z" fill="#AAAAAA"/>
					<path d="M11.5928 10.2944C10.5822 9.26824 9.24243 8.70312 7.82031 8.70312H6.17969C4.75759 8.70312 3.4178 9.26824 2.40718 10.2944C1.4015 11.3155 0.847656 12.6634 0.847656 14.0898C0.847656 14.3164 1.0313 14.5 1.25781 14.5H12.7422C12.9687 14.5 13.1523 14.3164 13.1523 14.0898C13.1523 12.6634 12.5985 11.3155 11.5928 10.2944Z" fill="#AAAAAA" />
				</svg>
				{ props.account_settings }
			</Link>
		</li>
		{/* <li>
			<Link to={ `${ match.url }/store-settings` } >
				<svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M13.2323 5.97983L12.1356 5.84042C12.0452 5.56217 11.9337 5.29383 11.8037 5.03892L12.4809 4.16683C12.7551 3.81392 12.723 3.31575 12.4098 3.01242L11.491 2.09367C11.1847 1.7775 10.6865 1.746 10.333 2.01958L9.46206 2.69683C9.20713 2.56675 8.93879 2.45533 8.65994 2.36492L8.52052 1.27C8.46802 0.83075 8.09525 0.5 7.65365 0.5H6.34693C5.90533 0.5 5.53256 0.83075 5.48006 1.26825L5.34064 2.36492C5.06179 2.45533 4.79345 2.56617 4.53852 2.69683L3.66699 2.01958C3.31464 1.746 2.81645 1.7775 2.51252 2.09075L1.59373 3.00892C1.27755 3.31575 1.24547 3.81392 1.51965 4.16742L2.19692 5.03892C2.06625 5.29383 1.95541 5.56217 1.86499 5.84042L0.770032 5.97983C0.330764 6.03233 0 6.40508 0 6.84667V8.15333C0 8.59492 0.330764 8.96767 0.768282 9.02017L1.86499 9.15958C1.95541 9.43783 2.06684 9.70617 2.19692 9.96108L1.51965 10.8332C1.24547 11.1861 1.27755 11.6842 1.59082 11.9876L2.5096 12.9063C2.81645 13.2219 3.31405 13.2534 3.66757 12.9798L4.53911 12.3026C4.79403 12.4332 5.06238 12.5447 5.34064 12.6345L5.48006 13.7288C5.53256 14.1692 5.90533 14.5 6.34693 14.5H7.65365C8.09525 14.5 8.46802 14.1692 8.52052 13.7317L8.65994 12.6351C8.9382 12.5447 9.20655 12.4332 9.46148 12.3032L10.3336 12.9804C10.6865 13.2546 11.1847 13.2225 11.4881 12.9093L12.4068 11.9905C12.723 11.6837 12.7551 11.1861 12.4809 10.8326L11.8037 9.96108C11.9343 9.70617 12.0458 9.43783 12.1356 9.15958L13.23 9.02017C13.6692 8.96767 14 8.59492 14 8.15333V6.84667C14.0006 6.40508 13.6698 6.03233 13.2323 5.97983ZM7.00029 10.4167C5.39197 10.4167 4.0835 9.10825 4.0835 7.5C4.0835 5.89175 5.39197 4.58333 7.00029 4.58333C8.60861 4.58333 9.91708 5.89175 9.91708 7.5C9.91708 9.10825 8.60861 10.4167 7.00029 10.4167Z" fill="#AAAAAA" />
				</svg>
				{ props.store_settings }
			</Link>
		</li> */}
		<li>
			<button onClick={ () => UserService.doLogout() } >
				<svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M8.75013 8.08342C8.42755 8.08342 8.16682 8.34479 8.16682 8.66673V11.0002C8.16682 11.3216 7.90545 11.5835 7.58341 11.5835H5.83338V2.83334C5.83338 2.33516 5.51604 1.89007 5.03891 1.7244L4.86619 1.66661H7.58341C7.90545 1.66661 8.16682 1.92852 8.16682 2.25003V4.00006C8.16682 4.32199 8.42755 4.58336 8.75013 4.58336C9.0727 4.58336 9.33343 4.32199 9.33343 4.00006V2.25003C9.33343 1.28518 8.54825 0.5 7.58341 0.5H1.31252C1.2903 0.5 1.27172 0.509934 1.25014 0.512818C1.22205 0.510468 1.19524 0.5 1.16672 0.5C0.523277 0.5 0 1.02317 0 1.66661V12.1668C0 12.665 0.317342 13.1101 0.794476 13.2757L4.3051 14.446C4.42409 14.4827 4.54244 14.5002 4.66677 14.5002C5.31021 14.5002 5.83338 13.977 5.83338 13.3335V12.7502H7.58341C8.54825 12.7502 9.33343 11.965 9.33343 11.0002V8.66673C9.33343 8.34479 9.0727 8.08342 8.75013 8.08342Z" fill="#AAAAAA"/>
					<path d="M13.8291 5.92238L11.4957 3.58905C11.329 3.4222 11.0781 3.372 10.86 3.46237C10.6424 3.55284 10.5 3.76572 10.5 4.00145V5.75148H8.16667C7.84462 5.75148 7.58325 6.01274 7.58325 6.33478C7.58325 6.65682 7.84462 6.91809 8.16667 6.91809H10.5V8.66811C10.5 8.90385 10.6424 9.11673 10.86 9.2072C11.0781 9.29756 11.329 9.24736 11.4957 9.08063L13.8291 6.74719C14.0572 6.51914 14.0572 6.15042 13.8291 5.92238Z" fill="#AAAAAA"/>
				</svg>
				{ props.logout }
			</button>
		</li>
	</ul>
</div>
	