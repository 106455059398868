import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Column } from '@ant-design/plots';

const DemoArea2 = () => {

  const data = [
    {
      "X": "0",
      "Y": 0
    },
    {
      "X": "1-3-22",
      "Y": 300
    },
    {
      "X": "2-3-22",
      "Y": 600
    },
    {
      "X": "3-3-22",
      "Y": 900
    },
    {
      "X": "4-3-22",
      "Y": 1200
    },
    {
      "X": "5-3-22",
      "Y": 200
    },
    {
      "X": "6-3-22",
      "Y": 100
    },
    {
      "X": "7-3-22",
      "Y": 500
    },
    {
      "X": "8-3-22",
      "Y": 200
    },
    {
      "X": "9-3-22",
      "Y": 100
    },
    {
      "X": "10-3-22",
      "Y": 330
    },
    {
      "X": "11-3-22",
      "Y": 440
    }
   
  ]

  const config = {
    data,
    xField: 'X',
    yField: 'Y',
    height: 400,
    xAxis: {    
      range: [0, 1],  
      grid: null 
    },
    yAxis: {
      grid: { line: { style: { stroke: '#eee' } } }, 
     
    }
  };

  
return <>
    <h4 className="analytics_header">{ "Analytics" }</h4>
    <div className='AnalyticsContainer'>
        <div className = "analytics">    
          <Column {...config} /> 
        </div>
    </div>
  </>
};

export default DemoArea2;