import React from 'react';
import { useTable, useGlobalFilter, useFlexLayout, useRowSelect } from 'react-table'
import { useState, useEffect, FC, ReactElement, Dispatch, SetStateAction } from 'react';
import { GlobalFilter } from "./globalFilter"
import { IndeterminateCheckbox } from "./indeterminateCheckbox"

import { HttpService } from "../../../services/HttpService"


interface ProductState {
	columns?: any,
	data?: string[]
}

const getStyles = (props, align = 'left') => [
	props,
	{
	  style: {
		justifyContent: align === 'right' ? 'flex-end' : 'flex-start',
		// alignItems: 'flex-start',
		// display: 'flex',
	  },
	},
  ]

const headerProps = (props, { column }) => getStyles(props, column.align)

const cellProps = (props, { cell }) => getStyles(props, cell.column.align)

export const ProductTable = ({ columns, data }) => {

	const defaultColumn = React.useMemo(
		() => ({
		  // When using the useFlexLayout:
		  minWidth: 30, // minWidth is only used as a limit for resizing
		  width: 150, // width is used for both the flex-basis and flex-grow
		  maxWidth: 200, // maxWidth is only used as a limit for resizing
		}),
		[]
	)

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		state: { globalFilter },
		visibleColumns,
		preGlobalFilteredRows,
		setGlobalFilter
	} = useTable(
		{ columns, data, defaultColumn }, 
		useGlobalFilter,
		useFlexLayout,
		useRowSelect,
		hooks => {
		  hooks.allColumns.push(columns => [
			// Let's make a column for selection
			{
			  id: 'selection',
			  disableResizing: true,
			  minWidth: 35,
			  width: 35,
			  maxWidth: 35,
			  // The header can use the table's getToggleAllRowsSelectedProps method
			  // to render a checkbox
			  Header: ({ getToggleAllRowsSelectedProps }) => (
				<div>
				  <IndeterminateCheckbox { ...getToggleAllRowsSelectedProps() } />
				</div>
			  ),
			  // The cell can use the individual row's getToggleRowSelectedProps method
			  // to the render a checkbox
			  Cell: ({ row }) => (
				<div>
				  <IndeterminateCheckbox { ...row.getToggleRowSelectedProps() } />
				</div>
			  ),
			},
			...columns,
		  ])
		  hooks.useInstanceBeforeDimensions.push(({ headerGroups }) => {
			// fix the parent group of the selection button to not be resizable
			const selectionGroupHeader = headerGroups[0].headers[0]
			selectionGroupHeader.canResize = false
		  })
		})
	return <table { ...getTableProps() } >
		<thead>
		<tr className={ "searchContainer" } >
		  <th colSpan={ visibleColumns.length } >
			<GlobalFilter
				preGlobalFilteredRows={ preGlobalFilteredRows }
				globalFilter={ globalFilter }
				setGlobalFilter={ setGlobalFilter }
			/>
		  </th>
		</tr>
			{ headerGroups.map(headerGroup => <div { ...headerGroup.getHeaderGroupProps({}) } className="tr_header" >
				{ headerGroup.headers.map(column => (
				<div { ...column.getHeaderProps(headerProps) } className="th">
					{ column.render('Header') }
				</div>
				))}
			</div>
			)}
		</thead>
		<tbody { ...getTableBodyProps() }>
			{ rows.map((row, i) => {
				prepareRow(row)
				return (
					<tr {...row.getRowProps()} className="tr">
					{
						row.cells.map(cell => <div { ...cell.getCellProps(cellProps) } className="td">
							{ cell.render('Cell') }
						</div> )
					}
					</tr>
				)
			}) }
		</tbody>
	</table>
}