import './welcomeToFeelter.css';

import React from 'react';
import { useState, useEffect, FC, ReactElement, Dispatch, SetStateAction, } from 'react';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	useHistory,
	useLocation,
	useParams
} from "react-router-dom";

import { HttpService } from "../../../services/HttpService"

import Modal from 'react-modal';
import { InstallationStepsAsideList } from './installationStepsAsideList';
import { PixelInstall } from "./pixelInstall"
import { WooCommercePluginInstallation } from "./wooCommercePluginInstallation" 
import { FeelterInstallationOptions } from "./InstallOptionsPage"
import { PluginInstallationChecking } from "./pluginInstallationChecking"

export interface installOptions {
	pageTitle: string,
	installOptions: {
		title: string,
		pageDescription: string,
		titleOptionsDescription: string
	}
	titleOptionsDescription: string
	manualInstallation: {
		description: string,
		title: string
	}
}


export const ModalDialogFixedContent = ({data, match}) => {
	let location = useLocation()

	const [showModal, setIsOpen] = React.useState(true);

	const [welcomeToFeelterData, setWelcomeToFeelter] = React.useState({} as installOptions );

	const fetchURL = "https://self-service.feelter.com/api/json/feelter_installation.json"
	useEffect(() => {
		const fetchData = async () => {
			const result = await HttpService.getAxiosClient().get(fetchURL);
			setWelcomeToFeelter(result.data.welcomeToFeelter)
		}
		fetchData()
	}, []);


	let history = useHistory();

	const handleOpenModal = () => {
		setIsOpen(true);
	}

	const afterOpenModal = () => {
		// references are now sync'd and can be accessed.
	}

	const handleCloseModal = () => {
		setIsOpen(false);
		history.goBack()
	}

	console.log("match.url ", match, location)
	
	return <div>
		{ welcomeToFeelterData.installOptions && <Modal 
			isOpen={ showModal }
			contentLabel="Minimal Modal Example"
			onRequestClose={ handleCloseModal }
			>
			<div>
				<header className="modalHeader">
					<h3 className="modalPageCapture">{ welcomeToFeelterData.pageTitle }</h3>
					<Link to={ `${ match.url }/home/recommendations` } className="closeButton" ></Link>
				</header>
				<main>
					<InstallationStepsAsideList />
					<div className="modalMain">
						<header>
							<div className="subheader">
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<g clipPath="url(#clip0)">
										<path d="M23.7545 11.3823L18.2045 5.50224C17.8634 5.14046 17.294 5.12396 16.9322 5.46535C16.571 5.80646 16.5542 6.37614 16.8956 6.73764L21.8627 11.9997L16.8956 17.2623C16.5542 17.6238 16.571 18.1932 16.9322 18.5346C17.1062 18.6987 17.3285 18.78 17.5499 18.78C17.789 18.78 18.0278 18.6852 18.2045 18.4983L23.7546 12.618C24.0818 12.2709 24.0818 11.7291 23.7545 11.3823Z" fill="#AAAAAA"/>
										<path d="M7.10465 17.2626L2.13783 12.0002L7.10465 6.73792C7.44576 6.37642 7.42926 5.80669 7.06776 5.46563C6.70653 5.12452 6.13653 5.14102 5.79547 5.50252L0.245432 11.3826C-0.0818513 11.7293 -0.0818513 12.2715 0.245432 12.6183L5.79575 18.4986C5.97275 18.6861 6.21125 18.7806 6.45036 18.7806C6.67175 18.7806 6.89408 18.699 7.06776 18.5349C7.42959 18.1938 7.44576 17.6241 7.10465 17.2626Z" fill="#AAAAAA"/>
										<path d="M13.4561 2.47024C12.965 2.39585 12.5054 2.73303 12.4304 3.22414L9.79034 20.5042C9.71534 20.9956 10.0528 21.455 10.5442 21.53C10.5905 21.5368 10.636 21.5402 10.6813 21.5402C11.1185 21.5402 11.5018 21.2213 11.57 20.7761L14.21 3.49597C14.285 3.00453 13.9475 2.54524 13.4561 2.47024Z" fill="#AAAAAA"/>
									</g>
									<defs>
										<clipPath id="clip0">
											<rect width="24" height="24" fill="white"/>
										</clipPath>
									</defs>
								</svg>
								<h3>Feelter installation</h3>
							</div>
							<p>Now that you’ve created a pixel, you need to implement some code on your website to make your pixel work.</p>
						</header>
						<Switch>
							<Route
								path={ `**/feelter-installation` }
								render={ () => <FeelterInstallationOptions data={ welcomeToFeelterData } match={ match } /> }
							/>
							<Route
								path={ `**/manual` }
								render={ () => <PixelInstall data={ welcomeToFeelterData } match={ match } /> }
							/>
							<Route
								path={ `**/woocommerce-plugin` }
								render={ () => <WooCommercePluginInstallation data={ welcomeToFeelterData } match={ match } /> }
							/>
							<Route
								path={ `**/plugin-installation-checking` }
								render={ () => <PluginInstallationChecking data={ welcomeToFeelterData } match={ match } /> }
							/>
						</Switch>
					</div>
				</main>
			</div>
		</Modal> }
	</div>
}